<template>
  <b-card v-if="renderPage">
    <div class="d-flex justify-content-between align-items-center">
      <h4 class="mb-0">Informações do arquivo</h4>
      <div>
        <b-button
          :disabled="exception.status != 'pendente'"
          variant="light-info"
          class="bg-light-info border-info mr-1"
          @click="proccessException"
        >
          <span class="mr-50 text-dark font-weight-bolder"> Processar Exceção </span>
          <feather-icon icon="PlayIcon" class="text-dark" />
        </b-button>
        <b-button
          variant="light-warning"
          class="bg-light-warning border-warning"
          @click="downloadFileByUrl"
        >
          <span class="mr-50 text-dark font-weight-bolder"> Baixar arquivo de exceção </span>
          <feather-icon icon="DownloadIcon" class="text-dark" />
        </b-button>
      </div>
    </div>

    <div class="mt-2">
      <b-table ref="exceptionFile" class="border" :items="exceptionFile" :fields="exceptionFileFields">
        <template #cell(status)="data">
          <span v-html="data.value"></span>
        </template>
      </b-table>
    </div>

    <div class="mt-2">
      <datatable
        no-body
        ref="baixas"
        v-if="baixasUrl && exception.status == 'operacoes_indefinidas'"
        :serviceUrl="baixasUrl"
        :customFields="[...this.operationsFields, ...baixaExtraFields]"
        @row-action="rowAction"
      >
        <template #table-header>
          <div class="d-flex justify-content-between align-items-center">
            <h4 class="mb-0">Baixas para definir manualmente</h4>
            <div class="d-flex">
              <b-button
                  variant="light-info"
                  class="bg-light-info border-info"
                  @click="baixaInBatch('liquidacao')"
                >
                  <span class="mr-50 text-dark font-weight-bolder"> Liquidar </span>
                </b-button>

                <b-button
                  variant="light-warning"
                  class="bg-light-warning border-warning ml-1"
                  @click="baixaInBatch('recompra')"
                >
                  <span class="mr-50 text-dark font-weight-bolder"> Recomprar </span>
                </b-button>

                <b-button
                  variant="light-danger"
                  class="bg-light-danger border-danger ml-1"
                  @click="baixaInBatch('cancelamento')"
                >
                  <span class="mr-50 text-dark font-weight-bolder"> Cancelar </span>
                </b-button>
            </div>
          </div>
        </template>
      </datatable>
      <datatable
        no-body
        ref="operations"
        v-else
        :serviceUrl="operacoesUrl"
        :customFields="operationsFields.slice(1, operationsFields.size)"
        showExportToExcell
        :exportToExcellAction="exportToExcel"
      >
        <template #table-header>
          <div class="d-flex justify-content-between align-items-center">
            <h4 class="mb-0">Eventos do arquivo</h4>
          </div>
        </template>
      </datatable>
    </div>
  </b-card>
</template>

<script>
import { BCard, BTable, BButton } from 'bootstrap-vue'
import Datatable from '@/views/common/crud/components/Datatable.vue'
import {
  brazilianFormattedDate,
  exceptionFileStatusFormatter,
  moneyFormatDatatable,
  tituloStatusLiquidacaoBadge,
  exceptionOperationStatusFormatter,
  exceptionOperationTipoFormatter,
  trueFalseToText
} from '@core/comp-functions/data_visualization/datatable'
import downloadFile from '@/utils/download-file'

export default {
  name: 'ExceptionFilesDetails',
  components: {
    BCard,
    BTable,
    BButton,
    Datatable,
  },
  data() {
    return {
      renderPage: true,
      operacoesUrl: '',
      baixasUrl: '',
      baixaSelecionada: null,
      exception: {},
      exceptionFile: [],
      operations: [],
      baixas: [],
      exceptionFileFields: [
        {
          key: 'id',
          label: 'Número',
        },
        {
          key: 'cedente.nome',
          label: 'Cedente',
        },
        {
          key: 'created_at',
          label: 'Data de envio',
          formatter: brazilianFormattedDate,
        },
        {
          key: 'data_referencia',
          label: 'Data de referência',
          formatter: brazilianFormattedDate,
        },
        {
          key: 'count_operacoes',
          label: 'Qtd. Operações',
        },
        {
          key: 'status',
          label: 'Status',
          formatter: exceptionFileStatusFormatter,
        },
      ],
      operationsFields: [
        {
          label: '',
          key: 'select',
        },
        {
          label: 'ID',
          key: 'id',
        },
        {
          label: 'Tipo',
          key: 'tipo',
          formatter: exceptionOperationTipoFormatter
        },
        {
          label: 'Executado?',
          key: 'executado',
          formatter: trueFalseToText
        },
        {
          label: 'Status',
          key: 'import_exception_status'
        },
        {
          label: 'Título',
          key: 'titulo.numero',
        },
      ],
      baixaExtraFields: [
        {
          label: '',
          key: 'baixaActions',
        },
      ]
    }
  },
  mounted() {
    this.getData()
    this.operacoesUrl = `/recebiveis/importacoes_excecoes/${this.$route.params.id}/operacoes`
    this.baixasUrl = `/recebiveis/importacoes_excecoes/${this.$route.params.id}/baixas`
  },
  methods: {
    async baixaInBatch(new_type) {
      let titulos = this.$refs.baixas.selectedRows.map(row => row.titulo.numero).join(', ')

      if(this.$refs.baixas.selectedRows.length === 0) {
        this.$swal({
          title: 'Nenhum titulo selecionado!',
          text: 'Selecione ao menos um titulo para liquidar.',
          icon: 'warning',
          confirmButtonText: 'Ok',
        })
        return
      } else {
        this.$swal({
          title: `Deseja mudar o status para ${new_type}?`,
          text: `Os titulos ${titulos} terão o status alterado para ${new_type} e não poderão ser alterados novamente.`,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Confirmar',
          cancelButtonText: 'Cancelar',
        }).then((result) => {
          if(result.isConfirmed) {
            this.updateBaixasType(this.$refs.baixas.selectedRows.map(row => row.id), new_type)
            this.$swal({
              title: 'Titulos atualizados!',
              text: `Os titulos ${titulos} tiveram status alterado para ${new_type} com sucesso!`,
              icon: 'success',
              confirmButtonText: 'Ok',
            })
          }
        })
      }
    },
    async getData() {
      this.renderPage = false
      const { data } = await this.$store.dispatch('admin/getExceptionFile', this.$route.params.id)
      this.exception = data.data
      this.exceptionFile = [this.exception]
      if(this.exception.status == 'operacoes_indefinidas'){
        this.getBaixas()
      }else{
        this.getOperations()
      }
      this.renderPage = true
    },
    async getOperations() {
      const { data } = await this.$store.dispatch(
        'admin/getExceptionFileOperations',
        this.$route.params.id,
      )
      this.operations = data.data
    },
    async getBaixas() {
      const { data } = await this.$store.dispatch(
        'admin/getExceptionFileBaixas',
        this.$route.params.id,
      )
      this.baixas = data.data
    },
    downloadFileByUrl() {
      window.open(this.exception?.arquivo, '_blank')
    },
    async proccessException() {
      await this.$store.dispatch('admin/proccessExceptionFile', this.$route.params.id)
      this.$swal({
        title: 'Arquivo em processamento!',
        text: 'O processamento do arquivo foi iniciado, aguarde alguns instantes até que ele seja concluido e atualize a página para verificar o novo status das operações!',
        icon: 'success',
        confirmButtonText: 'Ok',
      })

    },
    async exportToExcel() {
      try {
        const { data } = await this.$store.dispatch('admin/exportExceptionFilesOperations', {
          id: this.$route.params.id,
          payload: this.$refs.operations.params,
        })
        downloadFile(data, `excecao_${this.exception.id}.xlsx`, 'text/xlsx')
      } catch (error) {}
    },
    rowAction({ action, payload }) {
      const actions = {
        setTipoBaixaCancelamento: 'setNewBaixaTypeModal',
        setTipoBaixaLiquidacao: 'setNewBaixaTypeModal',
        setTipoBaixaRecompra: 'setNewBaixaTypeModal',
      }
      return this[actions[action]](payload, action)
    },
    async setNewBaixaTypeModal(payload, action) {
      if(payload.tipo != 'baixa') return;
      const tipo = {
        setTipoBaixaCancelamento: 'cancelamento',
        setTipoBaixaLiquidacao: 'liquidacao',
        setTipoBaixaRecompra: 'recompra',
      }[action]
      const tipoNome = { cancelamento: 'Cancelamento', liquidacao: 'Liquidação', recompra: 'Recompra' }[tipo]

      this.$swal({
        title: `Definir como ${tipoNome}?`,
        text: `O tipo da operação de exceção será definido como ${tipoNome} e não será possivel fazer uma nova alteração.`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Confirmar',
        cancelButtonText: 'Cancelar',
      }).then((result) => {
        if (result.value) {
          this.updateBaixaType(payload.id, tipo)
        }
      })
    },
    async updateBaixaType(id, newType){
      try {
        const { data } = await this.$store.dispatch('admin/setExceptionOperationBaixaType', {
          id: id,
          tipo: newType,
        })
        if (data) {
          await this.getData()
        }
      } catch (error) {}
    },
    async updateBaixasType(ids, newType){
      try {
        const { data } = await this.$store.dispatch('admin/setExceptionOperationBaixasType', {
          ids: ids,
          tipo: newType,
        })
        if (data) {
          await this.getData()
        }
      } catch (error) {}
    }
  },
}
</script>

<style lang="scss"></style>
